//
//  Tooltip
//


// Base
.tooltip {
    --#{$prefix}tooltip-color: var(--xgn-tooltip-color);
    --#{$prefix}tooltip-bg: var(--xgn-tooltip-bg);
    --#{$prefix}tooltip-opacity: var(--xgn-tooltip-opacity);

    .tooltip-inner {
        box-shadow: var(--xgn-tooltip-box-shadow);
    }

    &.tooltop-auto-width {
       	.tooltip-inner {
       		white-space: nowrap;
  			max-width: none;
        }
    }

    // Inverse
    &.tooltip-inverse {
        @include tooltip-theme(
            $bg-color: var(--xgn-dark), 
            $color: var(--xgn-dark-inverse),
            $arrow-color: var(--xgn-dark),
        );
    }
}
